import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticPageStyle from "ps-assets/jss/customStaticPageStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const freeLookPeriod = ({ classes, data }) => {
  var Images = data.allStrapiImages.edges;
  var clrImage = Images[1];
  var pageContent = data.allStrapiEntities.edges.map(document => {
    var summary = document.node.summary.replace(/\n/g, `<br />`);
    return (
      <GridItem
        md={8}
        sm={8}
        className={classNames(classes.mrAuto, classes.mlAuto)}
        key={document.node.id}
      >
        {/* <h2 className={classNames(classes.title, classes.textCenter)}>
          {document.node.title}
        </h2> */}
        <br />
        <h5 className={classes.description}>
          <div dangerouslySetInnerHTML={{ __html: summary }} />
          <Img fixed={document.node.Image.childImageSharp.fixed} alt="sign" />
          <br />
          {document.node.description}
        </h5>
        <a href={document.node.url} rel="noopener noreferrer">
          <Button round style={{ backgroundColor: `#35488a` }}>
            Download Application Form
          </Button>
        </a>
      </GridItem>
    );
  });

  return (
    <Layout
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <Helmet key="helmetTags">
        <title>Free Look Period | StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link
            rel="canonical"
            href="https://www.starhealth.in/free-look-period"
          />
          <meta
            name="description"
            content="Please use the free look period to review the terms and conditions of the policy that you purchase from StarHealth.in, within this free look period at any point of time if you want you can cancel the policy."
          />
          <meta name="title" content="Free Look Period | StarHealth.in" />
          <meta name="twitter:title" content="Free Look Period | StarHealth.in" />
          <meta property="og:title" content="Free Look Period | StarHealth.in" />
          <meta property="og:description" content="Please use the free look period to review the terms and conditions of the policy that you purchase from StarHealth.in, within this free look period at any point of time if you want you can cancel the policy." />
          <meta property="og:url" content="https://www.starhealth.in/free-look-period"/>
          <meta name="Free Look Period | StarHealth.in" />
          <meta name="twitter:description" content="Please use the free look period to review the terms and conditions of the policy that you purchase from StarHealth.in, within this free look period at any point of time if you want you can cancel the policy." />
          <meta property="twitter:url" content="https://www.starhealth.in/free-look-period" />
        </Helmet>
      )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100 }}
      >
        <div className={classes.team}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>{pageContent}</GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};
freeLookPeriod.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};
export default withStyles(customStaticPageStyle)(freeLookPeriod);

export const aboutUsQuery = graphql`
  query FreeLookPeriod {
    allStrapiEntities(filter: { category: { eq: "freeLookPeriod" } }) {
      edges {
        node {
          id
          title
          summary
          description
          category
          displayType
          url
          Image {
            childImageSharp {
              fixed {
                src
                srcSet
                base64
                height
                width
              }
            }
          }
        }
      }
    }
    allStrapiImages(filter: { section: { category: { eq: "free_look" } } }) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
